'use client';

import { useTranslations } from 'next-intl';
import Btn from '../ui/Btn';

const FooterTop = () => {
  const t = useTranslations('actions');

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Btn
      color="white"
      variant="outlined"
      size="large"
      uppercase
      onClick={handleScrollToTop}
      endIcon="arrowUp"
      animateIcon>
      {t('backToTop')}
    </Btn>
  );
};

export default FooterTop;
