import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/@apollo/client-react-streaming/dist/index.cc.cjs");
;
import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/vercel/path1/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/@liquorice/next-apollo/src/ApolloWrapper/ApolloWrapperInternal.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/sanitize.css/sanitize.css");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/sanitize.css/forms.css");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/sanitize.css/typography.css");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts/index.tsx\",\"import\":\"\",\"arguments\":[{\"variable\":\"--atlas\",\"src\":[{\"path\":\"./atlas/atlas-bold.otf\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"./atlas/atlas-regular.otf\",\"weight\":\"400\",\"style\":\"normal\"}]}],\"variableName\":\"atlas\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts/index.tsx\",\"import\":\"\",\"arguments\":[{\"variable\":\"--rebond\",\"src\":[{\"path\":\"./rebond/rebond-semibold.otf\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"./rebond/rebond-semibold.ttf\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"./rebond/rebond-semibold.woff\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"./rebond/rebond-semibold.woff2\",\"weight\":\"600\",\"style\":\"normal\"}]}],\"variableName\":\"rebond\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/AppRoot/AppRoot.css.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/EntryTheme/EntryTheme.css.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/src/components/Footer/Footer.Top.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/Icon/Icon.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/Logo/Logo.css.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/ui/Box/Box.css.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/ui/Btn/Btn.css.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/ui/Btn/Btn.Icon.css.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/ui/Container/Container.css.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/ui/Divider/Divider.css.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/ui/Flex/Flex.css.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/ui/Grid/Grid.css.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path1/src/components/ui/Txt/Txt.css.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path1/src/styles/global.css");
;
import(/* webpackMode: "eager" */ "/vercel/path1/src/styles/partials/palette.css.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path1/src/styles/sprinkles.css.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path1/src/styles/vars.css.ts");
